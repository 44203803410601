import { useCallback, useEffect, useState } from 'react'

import useDebounce from 'common/hooks/useDebounce'

type Coords = {
  latitude: number
  longitude: number
}

type SearchedLocation = null | {
  address: string
  coordinates: Coords
}

type Feature = {
  geometry: {
    type: string
    coordinates: number[]
  }
  id: string
  properties: {
    mapbox_id: string
    feature_type: string
    name: string
    place_formatted: string
    additional_feature_types: string[]
    bbox: number[]
    context: {
      country: {
        country_code: string
        country_code_alpha_3: string
        id: string
        name: string
        wikidata_id: string
      }
      place: {
        id: string
        name: string
        wikidata_id: string
      }
      region: {
        id: string
        name: string
        wikidata_id: string
      }
    }
    coordinates: Coords
  }
  type: string
}

export type Location = {
  attribution: string
  features: Feature[]
  type: string
}

type Status = 'idle' | 'loading' | 'error'

type Config = {
  search: string
  userLocation: null | {
    lat: number
    lng: number
  }
}

type Return = {
  status: Status
  data: Location[]
  searchedLocation: SearchedLocation
}

export const useMapboxLocation = ({ search, userLocation }: Config): Return => {
  const [status, setStatus] = useState<Status>('idle')
  const [data, setData] = useState([])
  const [searchedLocation, setSearchedLocation] =
    useState<SearchedLocation>(null)

  const debouncedSearch = useDebounce(search)

  const fetchLocation = useCallback(async () => {
    if (!debouncedSearch && !userLocation) {
      setStatus('idle')
      return
    }

    setStatus('loading')

    try {
      const res = await fetch('/api/mapbox-search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          search: debouncedSearch,
          lat: userLocation?.lat.toFixed(4),
          lng: userLocation?.lng.toFixed(4),
        }),
      })
      const response = await res.json()
      setStatus('idle')
      setData(response)
      setSearchedLocation({
        address: response.features[0].properties.name,
        coordinates: {
          latitude: response.features[0].properties.coordinates.latitude,
          longitude: response.features[0].properties.coordinates.longitude,
        },
      })
    } catch (error) {
      setStatus('error')
      setData([])
    }
  }, [debouncedSearch, userLocation])

  useEffect(() => {
    fetchLocation()
  }, [fetchLocation])

  return { status, data, searchedLocation }
}
