import mapboxgl, {
  Map as mapboxGlMap,
  NavigationControl as mapboxGlNC,
  Marker as mapboxGlMarker,
} from 'mapbox-gl'
import React, { useEffect, useMemo, useRef } from 'react'
import styled, { keyframes } from 'styled-components'

import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_STYLE } from 'common/constants'
import usePrevious from 'common/hooks/usePrevious'
import { theme } from 'common/theme'
import { addAlphaToColor } from 'common/utils/style'

type Props = {
  initialPosition?: { latitude?: number; longitude?: number; zoom?: number }
  clinics: {
    id: string
    latitude: number
    longitude: number
  }[]
  selectedClinic: string | null
  hoveredClinic: string | null
  onSelect: (id: string) => void
  onMouse: (id: string | null) => void
  searchedLocation: { latitude: number; longitude: number } | null
}

export const ClinicsMap = ({
  initialPosition,
  clinics,
  onSelect,
  onMouse,
  selectedClinic,
  hoveredClinic,
  searchedLocation,
  ...props
}: Props): JSX.Element => {
  const mapRef = useRef<mapboxgl.Map>()
  const mapContainerRef = useRef(null)

  const clinicsData: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: clinics?.map((clinic) => {
        return {
          type: 'Feature',
          id: clinic.id,
          properties: {
            id: clinic.id,
            name: clinic.id,
          },
          geometry: {
            type: 'Point',
            coordinates: [clinic.longitude, clinic.latitude, 0.0],
          },
        }
      }),
    }
  }, [clinics])

  // This starts and destroys the mapbox instance, we use ref to optimize the DOM responsiveness
  useEffect(() => {
    const center: mapboxgl.LngLatLike =
      initialPosition?.latitude && initialPosition?.longitude
        ? [initialPosition.longitude, initialPosition.latitude]
        : [15.2551, 54.526] // center of europe
    const zoom = initialPosition?.zoom || 3

    mapRef.current = new mapboxGlMap({
      accessToken: process.env.NEXT_PUBLIC_MAPBOXGL_TOKEN,
      container: mapContainerRef.current || '',
      style: MAPBOX_STYLE,
      center,
      zoom,
      scrollZoom: true,
      attributionControl: false, // Disable the default attribution control
    })

    // Loads the map configs
    mapRef.current?.on('load', () => {
      mapRef?.current?.resize()

      // Add clinics
      mapRef.current?.addSource('clinics', {
        type: 'geojson',
        data: clinicsData,
        cluster: true,
        clusterMaxZoom: 8, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      })

      // Add a layer for the unclustered points (clinics)
      mapRef.current?.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'clinics',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': theme.colors.palette.orange.default,
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-stroke-color': theme.colors.palette.white,
        },
      })

      mapRef.current?.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'clinics',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': theme.colors.palette.orange.default,
          'circle-stroke-width': 3,
          'circle-stroke-color': theme.colors.palette.white,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      })

      // Add a layer for the clusters count labels
      mapRef.current?.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'clinics',
        layout: {
          'text-field': '{point_count}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': theme.colors.palette.white,
        },
      })
    })

    // Select clicked clinic
    mapRef.current?.on('click', 'unclustered-point', (e) => {
      if (e.features) {
        onSelect(e.features[0]?.properties?.id)
      }
    })

    // Highlight hovered clinic
    mapRef.current.on('mouseover', 'unclustered-point', (e) => {
      const canvas = mapRef.current?.getCanvas()
      if (!canvas) return

      canvas.style.cursor = 'pointer'
      if (e.features && e.features[0]?.properties?.id !== selectedClinic) {
        onMouse(e.features[0]?.properties?.id)
      }
    })

    mapRef.current?.on('mouseleave', 'unclustered-point', () => {
      const canvas = mapRef.current?.getCanvas()
      if (!canvas) return

      canvas.style.cursor = ''
      onMouse(null)
    })

    // Change cursor while hovering clusters
    mapRef.current?.on('mouseover', 'clusters', () => {
      const canvas = mapRef.current?.getCanvas()
      if (!canvas) return
      canvas.style.cursor = 'pointer'
    })

    mapRef.current?.on('mouseleave', 'clusters', () => {
      const canvas = mapRef.current?.getCanvas()
      if (!canvas) return

      canvas.style.cursor = ''
    })

    // inspect a cluster on click
    mapRef.current?.on(
      'click',
      'clusters',
      (
        e: mapboxgl.MapMouseEvent & {
          features?: mapboxgl.MapboxGeoJSONFeature[]
        } & mapboxgl.EventData
      ): void => {
        const zoom = mapRef.current?.getZoom() || 0

        const geometry = e.features && (e.features[0].geometry as GeoJSON.Point)
        const coordinates = geometry?.coordinates as mapboxgl.LngLatLike

        mapRef.current?.easeTo({
          center: coordinates,
          zoom: zoom + 2,
        })
      }
    )

    mapRef.current?.addControl(
      new mapboxGlNC({ showCompass: false }),
      'top-right'
    )

    return () => {
      mapRef.current?.remove()
    }
  }, [clinicsData])

  // Add  markers on hover and on select
  useEffect(
    function addMarkers() {
      const els: HTMLDivElement[] = []
      const markers: mapboxGlMarker[] = []

      // Add hovered location to map
      if (hoveredClinic && mapRef.current) {
        const el = document.createElement('div')
        el.className = 'marker location'

        const clinic = clinics?.find((clinic) => clinic.id === hoveredClinic)

        if (clinic) {
          els.push(el)

          const marker = new mapboxGlMarker(el)
            .setLngLat([clinic.longitude, clinic.latitude])
            .addTo(mapRef.current)
          markers.push(marker)

          el.className += ' hovered'
        }
      }

      // Add selected location to map
      if (selectedClinic && mapRef.current) {
        const el = document.createElement('div')
        el.className = 'marker location'

        const clinic = clinics?.find((clinic) => clinic.id === selectedClinic)

        if (clinic) {
          els.push(el)

          const marker = new mapboxGlMarker(el)
            .setLngLat([clinic.longitude, clinic.latitude])
            .addTo(mapRef.current)
          markers.push(marker)

          el.className += ' selected'
        }
      }

      return () => {
        markers.forEach((marker) => {
          marker.remove()
        })
      }
    },
    [clinics, clinicsData, hoveredClinic, selectedClinic]
  )

  const prevSelectedClinic = usePrevious(selectedClinic)
  useEffect(
    function flyToSelection() {
      if (prevSelectedClinic !== selectedClinic && !!selectedClinic) {
        const target = clinics.find((clinic) => clinic.id === selectedClinic)

        if (target && mapRef.current) {
          mapRef.current.flyTo({
            center: [target.longitude, target.latitude],
            zoom: 11,
          })
        }
      }
    },
    [clinics, prevSelectedClinic, selectedClinic]
  )

  const prevSearchedLocation = usePrevious(searchedLocation)
  useEffect(
    function flyToSearch() {
      if (
        JSON.stringify(searchedLocation) &&
        !!searchedLocation &&
        mapRef.current
      ) {
        mapRef.current.flyTo({
          center: [searchedLocation.longitude, searchedLocation.latitude],
          zoom: 8,
        })
      }
    },
    [prevSearchedLocation, searchedLocation]
  )

  return (
    <Container {...props}>
      <MapContainer ref={mapContainerRef} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 40vh;

  .mapboxgl-map {
    height: 100%;
  }
`

const pulse = (color: string) => keyframes`
from {
  box-shadow: 0 0 0px 4px ${addAlphaToColor(color, 100)};
}
to {
  box-shadow: 0 0 0px 10px ${addAlphaToColor(color, 50)};
}
`

const size = keyframes`
from {
  width: 1rem;
  height: 1rem;
}
to {
  width: 1.25rem;
  height: 1.25rem;
}
`

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .marker {
    width: 1rem;
    height: 1rem;
    border: 2px solid white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    z-index: 998;

    background-color: ${({ theme }) => theme.colors.palette.orange.default};
  }

  .location {
    width: 0.75rem;
    height: 0.75rem;
    z-index: 998;
    background-color: ${({ theme }) => theme.colors.palette.orange.default};
    animation: ${({ theme }) => pulse(theme.colors.palette.orange.default)} 1s
      ease infinite alternate;
  }

  .hovered {
    z-index: 999;
    animation: ${size} 0.5s ease infinite alternate;
  }

  .selected {
    z-index: 999;
    animation: ${({ theme }) => pulse(theme.colors.palette.orange.light)} 1s
      ease infinite alternate;
  }
`
