import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Checkbox, Container, SearchInput, Text } from 'common/UI'
import { ErrorText } from 'common/UI/Field'
import useDebounce from 'common/hooks/useDebounce'
import { ClinicLocatorStoryblok } from 'common/types'
import { getClinicDistance, getDistance } from 'common/utils/content'
import { addAlphaToColor } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'
import { Empty } from 'modules/AllEntries/Empty'
import { ContentLoader } from 'modules/shared'

import { ClinicLocatorThumb } from './ClinicLocatorThumb'
import { ClinicsMap } from './ClinicsMap'
import { GeolocationStatus } from './types'
import { useFetchClinicLocations } from './useFetchClinics'
import { useMapboxLocation } from './useMapboxLocation'

type Props = {
  block: ClinicLocatorStoryblok
}

const LOADER_AMOUNT = 4

export const countryStrings: Record<string, string> = {
  gb: 'countries.gb',
  nl: 'countries.nl',
  pl: 'countries.pl',
}

export const ClinicLocator = ({ block, ...props }: Props): JSX.Element => {
  const {
    query: { lang },
  } = useRouter()
  const [, region] = ((lang as string) || '').split('-')
  const { i18n } = useTranslation()

  const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>(
    GeolocationStatus.Loading
  )

  const [userLocation, setUserLocation] = useState<{
    lat: number
    lng: number
  } | null>(null)

  useEffect(() => {
    try {
      if (
        'geolocation' in navigator &&
        (window.location.protocol === 'https:' ||
          process.env.NODE_ENV === 'development')
      ) {
        setGeolocationStatus(GeolocationStatus.Ready)
      }
    } catch (error) {
      setGeolocationStatus(GeolocationStatus.Unsupported)
    }
  }, [])

  const disableNearMe = () => {
    setUserLocation(null)
    setGeolocationStatus(GeolocationStatus.Ready)
  }

  const handleNearMe = useCallback(() => {
    if (userLocation) {
      disableNearMe()
      return
    }

    setGeolocationStatus(GeolocationStatus.Loading)

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocationStatus(GeolocationStatus.Success)

        setSearch('')

        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      (error) => {
        console.error(error)
        setGeolocationStatus(GeolocationStatus.Error)
      },
      {
        enableHighAccuracy: true,
      }
    )
  }, [userLocation])

  const initialPosition = useMemo(() => {
    return {
      latitude: block.initial_latitude,
      longitude: block.initial_longitude,
      zoom: block.initial_zoom,
    }
  }, [block])

  /** Select clinic */
  const [hoveredClinic, setHoveredClinic] = useState<string | null>(null)
  const [selectedClinic, setSelectedClinic] = useState<string | null>(null)

  const handleClinicSelect = useCallback((id: string) => {
    setSelectedClinic(id)
  }, [])

  useEffect(
    function scrollToSelectedClinic() {
      if (selectedClinic) {
        const el = document.getElementById(selectedClinic)
        if (el)
          window.scrollTo({
            top: el.getBoundingClientRect().top + window.scrollY - 80,
            behavior: 'smooth',
          })
      }
    },
    [selectedClinic]
  )

  /** Clinics Data */
  const [search, setSearch] = useState('')
  const {
    data,
    isError: clinicLoadingErrored,
    isLoading: isLoadingClinics,
  } = useFetchClinicLocations()

  const debouncedSearch = useDebounce(search)
  const { status: locationStatus, searchedLocation: mapboxSearchedLocation } =
    useMapboxLocation({
      search,
      userLocation,
    })

  const locationStatusIsLoading = locationStatus === 'loading'
  const locationStatusIsIdle = locationStatus === 'idle'
  const locationStatusIsError = locationStatus === 'error'

  const [onlyCurrCountry, setOnlyCurrCountry] = useState(false)

  const hasSearch = debouncedSearch || userLocation
  const clinics = useMemo(() => {
    let c = onlyCurrCountry
      ? data.filter((clinic) => clinic.content.country === region)
      : data

    if (!hasSearch && !!region) {
      c = c.sort((a) => {
        return a.content.country === region ? -1 : 1
      })
    }

    // Sort Clinics by distance
    if (userLocation) {
      const clinicsWithDistance = c.map((clinic) => {
        const coordinates = clinic.content.coordinates.split(',')
        const distance = getDistance(
          Number(coordinates[0]),
          Number(coordinates[1]),
          userLocation.lat,
          userLocation.lng
        )
        return {
          ...clinic,
          distance,
        }
      })

      c = clinicsWithDistance.sort((a, b) => {
        return a.distance - b.distance
      })
    }

    return c
  }, [onlyCurrCountry, data, hasSearch, region])

  const clinicsArray = useMemo(() => {
    const allClinics = clinics?.map((clinic) => {
      const coordinates = clinic.content.coordinates.split(',')
      const distance = getClinicDistance({
        userLocation,
        mapboxSearchedLocation,
        clinicCoordinates: coordinates,
      })

      return {
        id: clinic.id,
        content: clinic.content,
        latitude: Number(coordinates[0]),
        longitude: Number(coordinates[1]),
        distance,
      }
    })

    if (userLocation || mapboxSearchedLocation) {
      return allClinics.sort((a, b) => {
        return (a.distance || 0) - (b.distance || 0)
      })
    }

    return allClinics
  }, [clinics, mapboxSearchedLocation, userLocation])

  const searchedAddress =
    mapboxSearchedLocation?.address ||
    `${mapboxSearchedLocation?.coordinates.latitude}, ${mapboxSearchedLocation?.coordinates.longitude}`

  return (
    <Wrapper {...props}>
      <Container variant="wide">
        <Content>
          <MapHolder>
            <ClinicsMap
              initialPosition={{
                latitude: initialPosition.latitude
                  ? +initialPosition.latitude
                  : undefined,
                longitude: initialPosition.longitude
                  ? +initialPosition.longitude
                  : undefined,
                zoom: initialPosition.zoom ? +initialPosition.zoom : undefined,
              }}
              selectedClinic={selectedClinic}
              hoveredClinic={hoveredClinic}
              onSelect={handleClinicSelect}
              onMouse={(clinic) => setHoveredClinic(clinic)}
              searchedLocation={
                mapboxSearchedLocation && (debouncedSearch || userLocation)
                  ? {
                      latitude: mapboxSearchedLocation.coordinates.latitude,
                      longitude: mapboxSearchedLocation.coordinates.longitude,
                    }
                  : null
              }
              clinics={clinicsArray}
            />
          </MapHolder>
          <div>
            <Header>
              <HeaderContent>
                <SearchHolder>
                  <Search
                    placeholder={i18n('clinicLocator.search')}
                    value={search}
                    onChange={(e) => {
                      disableNearMe()
                      setSearch(e.currentTarget.value)
                    }}
                  />
                  {geolocationStatus !== 'unsupported' && (
                    <NearMe
                      disabled={locationStatusIsLoading}
                      checked={!!userLocation}
                      label={i18n('clinicLocator.nearMe')}
                      onChange={handleNearMe}
                    />
                  )}
                </SearchHolder>

                {!!region && (
                  <FiltersHolder>
                    <Checkbox
                      label={i18n('clinicLocator.onlyCountry', {
                        currCountry:
                          region && countryStrings[region]
                            ? i18n(countryStrings[region])
                            : i18n('clinicLocator.domestic'),
                      })}
                      checked={onlyCurrCountry}
                      onChange={() => {
                        setOnlyCurrCountry((prev) => !prev)
                      }}
                    />
                  </FiltersHolder>
                )}
              </HeaderContent>

              {geolocationStatus === 'error' && (
                <ErrorText
                  as="div"
                  variant="fourteen"
                  css={{ marginTop: '1rem', display: 'block' }}
                >
                  {i18n('clinicLocator.geolocationError')}
                </ErrorText>
              )}
            </Header>

            {locationStatusIsIdle && hasSearch && (
              <LocationWrapper>
                <LocationBox isError={!mapboxSearchedLocation}>
                  <Text
                    as="p"
                    variant="sixteen"
                    color={
                      mapboxSearchedLocation ? 'foreground.subtle' : 'ui.error'
                    }
                  >
                    {mapboxSearchedLocation
                      ? i18n('clinicLocator.searchingFor', {
                          location: searchedAddress,
                        })
                      : i18n('clinicLocator.locationNotFound')}
                  </Text>
                </LocationBox>
              </LocationWrapper>
            )}
            <ClinicList>
              {(clinicLoadingErrored || locationStatusIsError) && (
                <Empty css={{ margin: '1rem 0' }} fullWidth isError={true} />
              )}

              {!isLoadingClinics &&
                !clinicLoadingErrored &&
                locationStatusIsIdle &&
                clinicsArray.length === 0 && (
                  <Empty css={{ margin: '1rem 0' }} fullWidth />
                )}

              {(isLoadingClinics || locationStatusIsLoading) &&
                !clinicLoadingErrored &&
                new Array(LOADER_AMOUNT).fill('').map((_, i) => (
                  <ContentLoader
                    key={i}
                    viewBox="0 0 620 150"
                    preserveAspectRatio="none"
                    style={{
                      opacity: Math.min(
                        ((LOADER_AMOUNT - 1 - i) * (100 / LOADER_AMOUNT - 1) +
                          10) /
                          100,
                        1
                      ),
                    }}
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="620" height="150" />
                  </ContentLoader>
                ))}

              {locationStatusIsIdle && clinicsArray.length > 0 && (
                <>
                  {clinicsArray.map((clinic) => {
                    const coordinates = clinic.content.coordinates.split(',')
                    let distance = null

                    if (userLocation) {
                      distance = getDistance(
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                        userLocation.lat,
                        userLocation.lng
                      )
                    } else if (
                      locationStatus == 'idle' &&
                      mapboxSearchedLocation?.coordinates
                    ) {
                      distance = getDistance(
                        Number(coordinates[0]),
                        Number(coordinates[1]),
                        mapboxSearchedLocation?.coordinates.latitude,
                        mapboxSearchedLocation?.coordinates.longitude
                      )
                    }

                    return (
                      <ThumbWrapper
                        key={clinic.id}
                        id={clinic.id}
                        selected={selectedClinic === clinic.id}
                        hovered={hoveredClinic === clinic.id}
                      >
                        <ClinicLocatorThumb
                          onSelect={() => {
                            setSelectedClinic(clinic.id)
                          }}
                          name={clinic.content.name}
                          image={clinic.content.image.filename}
                          href={clinic.content.website_url || '/'}
                          distance={distance}
                          address={clinic.content.address || ''}
                          country={
                            countryStrings[clinic.content.country]
                              ? i18n(countryStrings[clinic.content.country])
                              : null
                          }
                          phone={clinic.content.phone_number}
                          onMouseEnter={() => {
                            setHoveredClinic(clinic.id)
                          }}
                          onMouseLeave={() => {
                            setHoveredClinic(null)
                          }}
                        />
                      </ThumbWrapper>
                    )
                  })}
                  <Text as="p" color="foreground.subtle" variant="fourteen">
                    {clinics.length === 1 ? (
                      <>
                        {mapboxSearchedLocation
                          ? i18n('clinicLocator.result.single.location', {
                              location: searchedAddress,
                            })
                          : i18n('clinicLocator.result.single')}
                      </>
                    ) : (
                      <>
                        {mapboxSearchedLocation
                          ? i18n('clinicLocator.result.multiple.location', {
                              x: clinics.length.toString(),
                              location: searchedAddress,
                            })
                          : i18n('clinicLocator.result.multiple', {
                              x: clinics.length.toString(),
                            })}
                      </>
                    )}
                  </Text>
                </>
              )}
            </ClinicList>
          </div>
        </Content>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 2rem 0;
`

const Content = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  position: relative;

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: 1fr 1fr;
  }
`

const MapHolder = styled.div`
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
  border-radius: 0.75rem;
  overflow: hidden;

  height: 20rem;

  ${({ theme }) => theme.media.lg} {
    position: sticky;
    top: calc(4rem + 1rem); // topnav height + margin

    height: calc(100vh - 4rem - 2rem);
  }
`

const Header = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;

  background-color: ${({ theme }) => theme.colors.background.subtle};

  border-radius: 0.75rem;
  overflow: hidden;

  box-shadow: ${({ theme }) => theme.shadows.subtle};
`

const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  // :( I have to target the label inside TextField to remove default padding
  label {
    padding: 0;
  }

  ${({ theme }) => theme.media.xl} {
    align-items: center;
    flex-direction: row;
  }
`

const SearchHolder = styled.div`
  position: relative;
  width: 100%;
`

const Search = styled(SearchInput)`
  ${({ theme }) => theme.media.xl} {
    padding-right: 7rem;
  }
`

const NearMe = styled(Checkbox)`
  margin-top: 1rem;

  ${({ theme }) => theme.media.xl} {
    margin-top: unset;

    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

const FiltersHolder = styled.div`
  white-space: nowrap;

  display: flex;
  align-items: center;
  flex: 1;

  margin-top: 1rem;

  ${({ theme }) => theme.media.xl} {
    margin-top: unset;

    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 1px solid
      ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};
  }
`

const ClinicList = styled.ul`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const ThumbWrapper = styled.li<{ selected: boolean; hovered: boolean }>`
  transition: box-shadow ease 0.75s;
  border-radius: 0.75rem;

  box-shadow: ${({ theme, selected, hovered }) =>
    `0 0 0px ${selected ? '6px' : '3px'} ${addAlphaToColor(
      theme.colors.palette.orange.light,
      selected || hovered ? 100 : 0
    )}`};
`

const LocationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`

const LocationBox = styled.div<{ isError: boolean }>`
  text-align: center;

  border: 1px dashed
    ${({ theme, isError }) =>
      isError ? theme.colors.ui.error : theme.colors.foreground.subtle};
  border-radius: 0.75rem;
  padding: 0.75rem;

  width: 100%;
`
